import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { CocktailRecipe } from '../components/CocktailRecipe/CocktailRecipe';

const CocktailRecipeTemplate = ({
  data: {
    datoCmsCocktailRecipe: {
      seoMetaTags,
      title,
      firstPublishedAt,
      introduction,
      images,
      modularBlocks,
      relatedArticles,
    },
    shopifyProduct,
  },
}) => {
  return (
    <Layout removeLinesBackground={true} seo={seoMetaTags}>
      <main>
        <CocktailRecipe
          heading={title}
          firstPublishedAt={firstPublishedAt}
          introduction={introduction}
          images={images}
          modularBlocks={modularBlocks}
          featuredProduct={shopifyProduct}
          relatedArticles={relatedArticles}
        />
      </main>
    </Layout>
  );
};

export const CocktailRecipeTemplateQuery = graphql`
  query CocktailRecipeTemplateQuery($id: String!, $featuredProduct: String) {
    datoCmsCocktailRecipe(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      firstPublishedAt: meta {
        robotDate: firstPublishedAt
        humanDate: firstPublishedAt(formatString: "DD / MM / YY")
      }
      baseLiquor {
        ...BaseLiquorFragment
      }
      introduction
      images {
        gatsbyImageData(width: 770, height: 1030)
        alt
      }
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...HighlightedImagesModularBlockFragment
        ...InternalVideoModularBlockFragment
      }
      relatedArticles {
        ...BlogCardFragment
        ...CocktailCardFragment
      }
    }
    shopifyProduct(handle: { eq: $featuredProduct }) {
      ...ProductCardFragment
    }
  }
`;

export default CocktailRecipeTemplate;
