import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../../styles';
import FeaturedProduct from '../FeaturedProduct';
import ModularBlocks from '../ModularBlocks';
import { RelatedArticles } from '../RelatedBlogPosts';
import { BodyLarge, H1 } from '../typography/Typography';
import { Container, AnimatedBlock, AnimatedWords } from '../ui';
import { Slideshow } from '../Slideshow/Slideshow';

const StyledCocktailRecipe = styled.article`
  padding-bottom: 100px;
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.small`
          display: grid;
          grid-template-columns: 1.8fr 1fr;
          column-gap: 20px;
        `}

  ${minBreakpointQuery.mlarge`
          column-gap: 30px;
        `}

        ${minBreakpointQuery.xlarge`
          column-gap: 40px;
        `}

        ${minBreakpointQuery.xxxlarge`
          column-gap: 50px;
          padding-left: 50px;
        `}
`;

const StyledHeader = styled.header`
  ${sectionMargins('60px', '160px')};

  margin-bottom: 50px;

  ${minBreakpointQuery.tiny`
          margin-bottom: 60px;
        `}

  ${minBreakpointQuery.small`
          margin-right: auto;
        `}

        ${minBreakpointQuery.xxxxlarge`
          margin-bottom: 70px;
        `}
`;

const StyledHeading = styled(H1)``;

const StyledContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
`;

const StyledIntroInner = styled(AnimatedBlock)``;

const StyledText = styled(BodyLarge)``;

const StyledImages = styled.section`
  display: none;

  ${minBreakpointQuery.small`
   display: block;
  `}
`;

const StyledSlideShowWrapper = styled.div`
  margin-top: 32px;

  ${minBreakpointQuery.small`
          display: none;
        `}
`;

const StyledImage = styled(GatsbyImage)``;

const StyledFeaturedProduct = styled(FeaturedProduct)`
  ${minBreakpointQuery.xxxlarge`
          padding-left: 90px;
        `}
`;

const StyledRelatedArticles = styled(RelatedArticles)`
  ${minBreakpointQuery.xxxlarge`
          padding-left: 90px;
        `}
`;

export const CocktailRecipe = ({
  heading,
  introduction,
  images,
  modularBlocks,
  featuredProduct,
  relatedArticles,
}) => (
  <StyledCocktailRecipe>
    <StyledContainer wide={true}>
      <StyledInner>
        <div>
          <StyledHeader>
            <Container narrower={true}>
              <StyledHeading>
                <AnimatedWords>{heading}</AnimatedWords>
              </StyledHeading>
            </Container>
          </StyledHeader>

          <StyledContainer narrower>
            <StyledIntroInner>
              <Container narrower={true}>
                <StyledText>{introduction}</StyledText>
              </Container>
            </StyledIntroInner>

            {Boolean(images?.length) && (
              <StyledSlideShowWrapper>
                <Slideshow
                  aspectRatio={0.75}
                  items={images}
                  renderItem={({ gatsbyImageData, alt }, id) => {
                    return (
                      <StyledImage key={id} image={gatsbyImageData} alt={alt} />
                    );
                  }}
                />
              </StyledSlideShowWrapper>
            )}

            <ModularBlocks items={modularBlocks} isBlogSingle={true} />
            {featuredProduct && (
              <StyledFeaturedProduct {...featuredProduct} is={true} />
            )}
          </StyledContainer>
        </div>
        {Boolean(images?.length) && (
          <StyledImages>
            {images.map(({ gatsbyImageData, alt }, id) => (
              <StyledImage key={id} image={gatsbyImageData} alt={alt} />
            ))}
          </StyledImages>
        )}
      </StyledInner>
    </StyledContainer>
    <StyledRelatedArticles items={relatedArticles} />
  </StyledCocktailRecipe>
);
