import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, Link } from './ui';
import ProductAddToCart from './ProductAddToCart';
import { BodySmall, H3, H5 } from './typography/Typography';

const StyledFeaturedProduct = styled.section`
  ${sectionMargins('60px', '160px')}
`;

const StyledContainer = styled(Container)`
  ${({ isBlogArticleOrigLayout, isCart }) => {
    if (isBlogArticleOrigLayout) {
      return css`
        max-width: 1020px;
      `;
    } else if (isCart) {
      return css`
        padding-right: 0;
        padding-left: 0;
      `;
    }
  }}
`;

const StyledInner = styled.article`
  display: grid;
  grid-template-columns: 1.15fr 1fr;
  column-gap: 16px;
  align-items: center;
  max-width: 640px;

  ${minBreakpointQuery.tsmall`
    gap: 20px;
  `}

  ${minBreakpointQuery.smedium`
    gap: 24px;
  `}

  ${minBreakpointQuery.medium`
    column-gap: 28px;
  `}

  ${minBreakpointQuery.large`
    column-gap: 34px;
  `}

  ${minBreakpointQuery.xxlarge`
    column-gap: 40px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  grid-row-start: 2;
  height: 100%;

  ${minBreakpointQuery.tsmall`
    grid-row: 1 / 4;
    grid-column-start: 1;
  `}
`;

const StyledHeading = styled(H5)`
  text-transform: uppercase;

  ${maxBreakpointQuery.tsmall`
    grid-column-start: 1;
    grid-row-start: 1;
    margin-bottom: 24px;
  `}

  ${minBreakpointQuery.tsmall`
    margin-top: 24px;
    align-self: start;
  `}
`;

const StyledProductContent = styled.div`
  ${maxBreakpointQuery.tsmall`
    grid-row-start: 2;
  `}

  ${minBreakpointQuery.tsmall`
    align-self: end;
  `}
`;

const StyledSubheading = styled(H3)`
  grid-row-start: 2;
`;

const StyledText = styled(BodySmall)`
  margin-top: 16px;
`;

const StyledProductAddToCart = styled(ProductAddToCart)`
  ${maxBreakpointQuery.tsmall`
    grid-row: 3;
    grid-column: 1 / 3;
    margin-top: 24px;
  `}

  ${minBreakpointQuery.tsmall`
    align-self: start;
  `}
`;

const FeaturedProduct = ({
  heading,
  slug,
  featuredImage,
  featuredMedia,
  description,
  variants,
  isBlogArticle,
  isBlogArticleOrigLayout,
  isCart,
  ...props
}) => (
  <StyledFeaturedProduct {...props}>
    <StyledContainer
      wide={true}
      isBlogArticleOrigLayout={isBlogArticleOrigLayout}
      isCart={isCart}
    >
      <StyledInner>
        {featuredImage?.gatsbyImageData && (
          <StyledImage
            image={featuredImage.gatsbyImageData}
            alt={featuredImage.alt || heading}
          />
        )}

        <StyledHeading
          dangerouslySetInnerHTML={{
            __html: isBlogArticle
              ? 'Products featured <br /> in this article:'
              : 'You might also like',
          }}
        />
        <StyledProductContent>
          <StyledSubheading>
            <Link to={`shop/${slug}`}>{heading}</Link>
          </StyledSubheading>
          {description && <StyledText>{description}</StyledText>}
        </StyledProductContent>
        <StyledProductAddToCart variants={variants} alt={true} />
      </StyledInner>
    </StyledContainer>
  </StyledFeaturedProduct>
);

export default FeaturedProduct;
