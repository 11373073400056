import React from 'react';
import styled from 'styled-components';
import {
  delays,
  minBreakpointQuery,
  sectionMargins,
  standardTransition,
} from '../styles';
import { Container, Heading, Link } from './ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import { H5 } from './typography/Typography';

// moved up for hover
const StyledImage = styled(GatsbyImage)`
  transition: ${standardTransition('transform')};
`;

const StyledBlogCard = styled.article`
  cursor: pointer;
  animation: ${standardTransition('fadeUp', undefined, undefined, delays.long)}
    both;

  &:hover {
    ${StyledImage} {
      transform: scale(1.15);
    }
  }
`;

const StyledImageWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
`;

const StyledContent = styled.div`
  margin-top: 24px;
  max-width: 340px;

  ${minBreakpointQuery.small`
    margin-top: 28px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 32px;
  `}
`;

const StyledHeading = styled(H5)`
  text-transform: uppercase;
`;

const basePathMap = {
  DatoCmsCocktailRecipe: '/cocktails',
  DatoCmsBlog: '/blog',
};

const RelatedArticle = ({
  __typename,
  portraitImage,
  cardImage,
  slug,
  title,
  heading,
}) => {
  const urlPath = `${basePathMap[__typename]}/${slug}`;

  const image = portraitImage || cardImage;

  return (
    <Link href={urlPath}>
      <StyledBlogCard>
        <StyledImageWrapper>
          <StyledImage image={image.gatsbyImageData} alt={image.alt} />
        </StyledImageWrapper>
        <StyledContent>
          <StyledHeading>{heading || title}</StyledHeading>
        </StyledContent>
      </StyledBlogCard>
    </Link>
  );
};

const StyledRelatedArticles = styled.section`
  ${sectionMargins('120px', '200px')}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 40px;
  margin-top: 30px;

  ${minBreakpointQuery.tiny`
    margin-top: 40px;
  `}

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.large`
    margin-top: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-top: 60px;
  `}
`;

export const RelatedArticles = ({ items, ...props }) => {
  console.log(items);

  return (
    items.length > 0 && (
      <StyledRelatedArticles {...props}>
        <Container wide={true}>
          <Heading>Related Articles</Heading>
          <StyledItems>
            {items.map((item, i) => (
              <RelatedArticle key={i} {...item} />
            ))}
          </StyledItems>
        </Container>
      </StyledRelatedArticles>
    )
  );
};
